@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

:root {
    --font-size-p: 20px;
    --app-color-title: #00292b;
    --app-letter-spacing-text: .01em;
    --app-color-button-primary: #455c8a;
    --app-letter-spacing-title: .025em;
    --font-family: Roboto, sans-serif;
    --border-radius: 3px;
    --margin-left: 0;
    --margin-right: 0;
    --disabled-opacity: .7;
    --app-font-family: 'Roboto', sans-serif;
}

.App {
    text-align: center;  
}

.App-body {
    /* background-color: #282c34; */
    background: url('../../assets/bg2.jpg');
    background-size: cover;
    background-position: center 5%;
    min-height: 100vh;
    display: flex;    
    flex-direction: column;    
    font-size: calc(10px + 2vmin);    
    color: var(--app-color-title);
    font-family: var(--app-font-family);
    font-weight: 300;
}

.App-body .form {    
    height: 100vh;    
    max-width: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f1f1f1;    
}

@media screen and (max-width: 950px) {
    .App-body .form {    
        width: 100vw;
    }
}

@media screen and (min-width: 950px) {
    .App-body .form {    
        width: 40vw;
    }
}

.App-body .form h3 {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: var(--font-size-p);    
    letter-spacing: var(--app-letter-spacing-text);
}

.App-body .logo img {
    padding: 1px;
    width: 100%;
    max-width: 200px;
    /* position: fixed;
    top: 5rem;
    left: 5rem; */
}

.App-body .form span {
    font-size: 1.2rem;
}

.App-body .form button.btn-login {
    appearance: auto;
    writing-mode: horizontal-tb !important;    
    text-rendering: auto;    
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;    
    cursor: default;
    box-sizing: border-box;    
    
    border-width: 2px;
    border-style: outset;    
    border-image: initial;
    background-color: var(--app-color-button-primary);
    color: #fff;    
    cursor: pointer;
    border: 0;
    letter-spacing: var(--app-letter-spacing-title);

    width: 100%;
    min-height: 2.8rem;
    padding: 0 2rem;
    border-radius: var(--border-radius);
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin-left: var(--margin-left);
    margin-right: var(--margin-right);
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: var(--app-font-family);
}